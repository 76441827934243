<template>
    <div>
      <v-btn
        elevation=""
        color="grey lighten-2"
        class="text-capitalize"
        @click="toggleNavbar"
      >
        <v-icon left>
          mdi-view-dashboard
        </v-icon>
        Categories
        <v-icon right>
          mdi-chevron-down
        </v-icon>
      </v-btn>


      <div 
        class="navbar-toggle-dropdown p-absolute" 
        :class="{'open': isToggleNavbar}"
      > 
         <Navbar />

      </div>

     
    </div>
</template>
<script>
  import Navbar from "@/views/home/navbar";
  export default {
    components: {
      Navbar,
      
    },
    data () {
      return {
        isToggleNavbar: false
       
      }
    },
    methods: {
      toggleNavbar() {
        this.isToggleNavbar = !this.isToggleNavbar
      }
    }
    
  }
</script>

<style lang="scss">
  .navbar-toggle-dropdown {
    
    z-index: 98;
    display: none;
    &.open {
      display: block;
      
    }
  }
</style>